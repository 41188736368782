import React from 'react';
import {Layout, Button, Avatar, Tooltip} from 'antd';
import asyncComponent from './../core/components/async';
import Config, {CheckUserPermissions} from './../config';
// import { AppConsumer } from '../context/app';
import Store from "../core/library/store";
import moment from 'moment';
import {generateHSL} from "../core/library/hash";

const Menu = asyncComponent(() => {
    return import(`./../core/components/menu.js`);
});

// const Header = asyncComponent(() => {
//     return import(`./../core/components/header/index.js`);
// });

const Router = asyncComponent(() => {
    return import('./../core/router/index.js');
});


class OnlineUsers extends React.Component {
    state = {
        online: [],
    };

    componentDidMount() {
        if (window.SHOPWS) {
            // window.headerWatchIntervalOnline = setInterval(() => {
            //     window.SHOPWS.send(JSON.stringify({action: WSActions.online}))
            // }, 250);
            window.SHOPWS.addEventListener('message', (e) => {
                const data = e.data || false;
                if (data) {
                    const event = JSON.parse(data);
                    if (event.action && event.action === 'online-users') {
                        const users = JSON.parse(event.message).sort((a, b) => {
                            return ('' + a.email).localeCompare(b.email);
                        });
                        this.setState({ online: users});
                    }
                }
            });
        }
    }

    componentWillUnmount() {
        if(window.headerWatchIntervalOnline) {
            clearInterval(window.headerWatchIntervalOnline);
        }
    }

    getUserName = (name) => {
        const params = name.split(' ');
        return params.map(i => i.toUpperCase()[0]).join('');
    }

    render() {
        const { online } = this.state;
        return (
            <div className="users--online">
                {online && online.map(i => {
                    return (
                        <Tooltip key={`online_user_${i.email}`}
                                 title={`${i.name} (${i.email}) последняя активность ${moment(i.last_activity).fromNow()}`}>
                            <Avatar onClick={() => {
                                window.location.open(`mailto:${i.email}`);
                            }}
                                    style={{backgroundColor: `rgb(${generateHSL(i.name)}`}}>{this.getUserName(i.name)}</Avatar>
                        </Tooltip>
                    );
                })}
            </div>
        )
    }
}


export class DefaultLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: Store.get('sidebar_collapsed') === '1',
            loading: false,
            languages: [],
            online: [],
        };
    }

    componentDidMount() {


    }


    // onCollapse = collapsed => {
    //     Store.set('sidebar_collapsed', collapsed ? '1' : '0')
    //     this.setState({collapsed});
    // };

    buildUserMenu = () => {
        let menu = [];
        for (let i in Config.MENU) {
            let menuItem = {...Config.MENU[i]};
            if (menuItem.submenu) {
                menuItem.submenu = menuItem.submenu.filter(i => {
                    if (!i.permission || i.permission === '') {
                        return true;
                    }
                    console.log('check', i.permission, i.title, CheckUserPermissions(i.permission))
                    return CheckUserPermissions(i.permission);
                })
            }

            // если меню скрыто - его добавляем всегда
            if (menuItem.hidden) {
                menu.push(menuItem)
            } else {
                // если нет установки прав доступа, но есть сабменю - проверяем, что оно не пустое, если его нет то добавляем
                if (!menuItem.permission || menuItem.permission === '') {
                    if (menuItem.submenu && menuItem.submenu.length > 0) {
                        menu.push(menuItem);
                    } else {
                        if (!menuItem.submenu) {
                            menu.push(menuItem)
                        }
                    }
                } else {
                    if (CheckUserPermissions(menuItem.permission)) {
                        menu.push(menuItem)
                    }
                }
            }
        }
        return menu;
    }



    render() {
        const menu =  this.buildUserMenu();


        return (
            <Layout style={{minHeight: '100vh'}}>
                <Layout.Header className={`main--header`}>
                    <div className={"main--header__nav"}>
                        <a href={"/"}><div className="logo" /></a>
                        <Menu items={menu || []} mode={`horizontal`}/>
                    </div>
                    <div className={"main--header__user"}>
                        <OnlineUsers />
                        <div className="user--info">
                            <Avatar size={34} />
                            {this.props.user.info.lastname} {this.props.user.info.firstname}
                        </div>
                        <Button icon={'logout'} onClick={() => {
                            Store.remove('oauth_refresh');
                            Store.remove('oauth_access');
                            window.location.href = 'https://auth.rt.com/logout'
                        }}/>
                    </div>
                </Layout.Header>
                <Layout.Content style={{ padding: '0 10px' }}>
                    <Layout style={{ padding: '12px 0', width: '100%', margin: '0 auto'}}>
                        <Router {...this.props} isPhotoUser={false}/>
                    </Layout>
                </Layout.Content>
            </Layout>
        );
    }
}

export default DefaultLayout;
