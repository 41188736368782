import React from 'react';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { ConnectedRouter, routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import ApiMiddleware from './core/middlewares/api';
import ApplicationReducer from './store';
import 'antd/dist/antd.css';
import App from './App';
import './sass/main.scss';

const history = createBrowserHistory();
const routersMiddleware = routerMiddleware(history);
const store = createStore(ApplicationReducer, composeWithDevTools(applyMiddleware(thunk, routersMiddleware, ApiMiddleware)));

// add global events
window.addEventListener('DOMContentLoaded', () => {
    document.addEventListener('keydown', (e) => {
        const keyCode = e.keyCode ? e.keyCode : e.charCode;
        switch (keyCode) {
            case 16: window.APP_SHIFT_PRESSED = true; break;
            default: break;
        }
    })

    document.addEventListener('keyup', (e) => {
        const keyCode = e.keyCode ? e.keyCode : e.charCode;
        switch (keyCode) {
            case 16: window.APP_SHIFT_PRESSED = false; break;
            default: break;
        }
    })
});

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>, document.getElementById('root'));
